import Link from 'next/link';
import { useRouter } from 'next/router';

import React, { memo, ReactElement } from 'react';

import Flex from '@/components/containers/flex';

interface Props {
  name: string;
  active: boolean;
  to: string;
  icon?: ReactElement<any, any>;
}

const NavLink: React.FC<Props> = ({ name, active, to, icon }) => {
  const router = useRouter();
  return (
    <Link passHref href={to} prefetch={false}>
      <a
        className={`w-full px-5 flex flex-row md:flex-col lg:flex-row space-x-2 md:space-x-0 lg:space-x-2 items-center justify-center lg:justify-start border-b-2   pb-3 md:pb-0 lg:pb-1 ${
          active && 'border-black'
        } cursor-pointer`}
      >
        {icon}
        <span
          className={`font-lamasans-bold md:text-sm lg:text-[0.9rem] xl:text-base md:text-center lg:text-left font-bold ${
            active ? 'text-black' : 'text-gray-400'
          } mt-1`}
        >
          {name}
        </span>
      </a>
    </Link>
  );
};

export default memo(NavLink);

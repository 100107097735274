import { verifyIntegrity } from '@/common/api/auth';
import useLayout from '@/common/hooks/use-layout';
import Spinner from '@/common/pages/spinner';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { fr } from 'yup-locales';

import { SessionProvider, useSession } from 'next-auth/react';
import React from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import * as yup from 'yup';

import '../../styles/globals.css';
import MissingInfo from './missing-info';
import SignIn from './signin';

yup.setLocale(fr); // Set the locale to French

function MyApp({ Component, pageProps: { session, ...pageProps }, ...appProps }: any) {
  const [queryClient] = React.useState(() => new QueryClient());
  const LayoutComponent = useLayout();
  return (
    <>
      <Head>
        <title>Watto - cagnottes et cartes cadeaux, pour être sûr de faire plaisir</title>
      </Head>
      <SessionProvider session={pageProps.session}>
        <QueryClientProvider client={queryClient}>
          {/* TODO: restore this after fixing the issue related to user session */}
          {Component.auth ? (
            <LayoutComponent>
              <Auth>
                <Component {...pageProps} />
              </Auth>
            </LayoutComponent>
          ) : (
            <Component {...pageProps} />
          )}
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </SessionProvider>
    </>
  );
}

function Auth({ children }: any) {
  const { data: session, status } = useSession({ required: false });
  const { data, isLoading } = useQuery('account-integrity', () => verifyIntegrity());
  if (data == false) return <MissingInfo />;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter();
  if (status === 'loading') return <Spinner />;
  const path = router.pathname;
  const isUser = !!session?.user;

  if (isUser) {
    return children;
  } else {
    if (!path.startsWith('/app/cagnotte/details')) return <SignIn />;
  }
}

export default MyApp;

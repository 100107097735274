import Link from 'next/link';

import { useSession } from 'next-auth/react';
import React, { memo } from 'react';

import PotLogo from '@/components/app-logo/pot-logo';
import Flex from '@/components/containers/flex';
import { ExpandMoreIcon } from '@/components/icons';
import UserAvatar from '@/components/user-avatar/user-avatar';

interface Props {
  user?: any;
  targetClass?: string;
  type?: number;
}

const Navbar = ({ user, targetClass, type = 1 }: Props) => {
  const { data: session } = useSession();

  return (
    <div className={`${targetClass ?? 'rounded-3xl w-full mx-auto content-center h-28'}`}>
      <Flex basis={'basis-full'} xMarginBetween={4}>
        <Link href='/' passHref>
          <Flex className='flex justify-center ml-7 md:ml-0 items-center w-16 xs:w-24 cursor-pointer'>
            <PotLogo width={'5rem'} height={'5rem'} />
          </Flex>
        </Link>
        <div className='flex w-1/2 justify-end items-center px-2 sm:px-10 space-x-2'>
          {user ? (
            <>
              <div className='md:hidden h-10 w-10'>
                <img src='/icons/burger-menu.svg' className='h-full w-full md:hidden' />
              </div>
              <Flex className='flex justify-center items-center '>
                <Flex className='flex   mr-5 md:mr-3 space-x-2 items-center'>
                  <UserAvatar type={type} />
                  <Flex className='  mt-1 md:mt-2 hidden md:block'>
                    <span className='text-primary text-lg'>{session?.user?.name}</span>
                  </Flex>
                  <Flex className='mt-3.5 hidden md:block'>
                    <ExpandMoreIcon />
                    {/* TODO: Add drop down related to the menu */}
                  </Flex>
                </Flex>
              </Flex>
            </>
          ) : (
            <div className='md:hidden h-10 w-10'>
              <img src='/icons/burger-menu.svg' className='h-full w-full md:hidden' />
            </div>
          )}
        </div>
      </Flex>
    </div>
  );
};

export default memo(Navbar);
